import React from "react";
import { Button, Tooltip } from "antd";

import QuestionCircle from "@/icons/modules/shared/question-circle.svg";

import type { TooltipPlacement } from "antd/es/tooltip";

import "./hint.scss";

type HintProps = {
  text: string | React.ReactNode;
  color?: string;
  placement?: TooltipPlacement;
};

const Hint = (props: HintProps) => {
  const { text, color, placement } = props;

  return (
    <Tooltip
      arrow={false}
      placement={placement || "bottom"}
      title={text}
      trigger="hover"
    >
      <Button
        ghost
        className="hint"
        icon={<QuestionCircle />}
        style={{ color: color || "#67727A" }}
      />
    </Tooltip>
  );
};

export { Hint };
