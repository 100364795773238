export const pageMap: Record<string, string> = {
  availability: "AVAILABILITY",
  "prices-discounts": "PRICES_AND_DISCOUNTS",
  "reviews-and-rating": "REVIEWS_AND_RATING",
  content: "CONTENT",
  sales: "SALES",
  "monthly-sales": "MONTHLY_SALES",
  locations: "LOCATIONS",
  "search-position": "SEARCH_POSITION",
  products: "PRODUCTS",
  "serp-products": "SERP_PRODUCTS",
};

export const sectionMap: Record<string, string> = {
  "general-information": "GENERAL_INFO",
  "my-lists": "TABLE",
  matrix: "MATRIX",
  "matrix-msrp": "MATRIX_2",
  images: "IMAGES",
  reviews: "REVIEWS",
  questions: "QUESTIONS",
  "stats-locations": "STATS",
  "indexing-stats": "INDEXING",
  stats: "SUMMARY_STATS",
};

export const permissionsMap: Record<string, string> = {
  content: "content",
  "content-general-information": "content_general_information",
  "content-my-lists": "content_table",
  "content-images": "content_images",
  availability: "availability",
  "availability-general-information": "availability_general_information",
  "availability-my-lists": "availability_table",
  "availability-matrix": "availability_matrix",
  "search-position": "visibility",
  "search-position-general-information": "visibility_general_information",
  "search-position-my-lists": "visibility_table",
  "prices-discounts": "prices_and_discounts",
  "prices-discounts-general-information":
    "prices_and_discounts_general_information",
  "prices-discounts-my-lists": "prices_and_discounts_table",
  "prices-discounts-matrix": "prices_and_discounts_matrix",
  "monthly-sales": "monthly_sales",
  "monthly-sales-general-information": "monthly_sales_general_information",
  // "monthly-sales-my-lists": "monthly_sales_table",
  "monthly-sales-matrix": "monthly_sales_matrix",
  sales: "sales",
  "sales-general-information": "sales_general_information",
  "sales-my-lists": "sales_table",
  "product-list": "assortment_management",
  "product-list-mapping": "assortment_management_mapping",
  "product-list-products": "assortment_management_product_list",
  "product-list-custom-fields": "assortment_management_custom_fields",
  "reviews-and-rating": "reviews_and_rating",
  "reviews-and-rating-general-information":
    "reviews_and_rating_general_information",
  "reviews-and-rating-my-lists": "reviews_and_rating_table",
  "reviews-table": "reviews_and_rating_reviews",
  "questions-table": "reviews_and_rating_questions",
  "planned-reports": "planned_reports",
  "planned-reports-ready": "planned_reports",
  "planned-reports-ready-download": "planned_reports",
  "planned-reports-planned": "planned_reports",
  "filter-groups": "filter_groups",
  "filter-groups-brand": "filter_groups",
  "filter-groups-seller": "filter_groups",
  "filter-groups-category": "filter_groups",
  "bi-report": "bi_reports",
  "bi-report-report": "bi_reports",
  "buy-box": "buy_box",
};
